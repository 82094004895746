import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"

import AboutMe from "../components/AboutMe/AboutMe"
import SEO from "../components/seo"

function Omnie({ data }) {
  return (
    <Layout>
      <SEO title="O mnie" description={data.strapiMetaTag.opis_strony} keywords={data.strapiMetaTag.tagi} />
      <AboutMe data={data.aboutMe} />
    </Layout>
  )
}

export default Omnie

export const query = graphql`
  query aboutMe {
    strapiMetaTag(nazwa_strony: {eq: "o_mnie"}) {
      opis_strony
      tagi
    }
    aboutMe: strapiOMnie {
      Imie
      lata_doswiadczenia
      opis
      zawod
      zdjecie {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cytat1 {
        Autor
        Tresc
      }
      cytat2 {
        Autor
        Tresc
      }
      cytat3 {
        Autor
        Tresc
      }
    }
  }
`
