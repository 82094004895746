import React from "react"

import classes from "./Quote.module.scss"
import FormatQuoteIcon from "@material-ui/icons/FormatQuote"

const Quote = ({ name, content }) => {
  return (
    <div className={classes.quote}>
      <FormatQuoteIcon className={classes.icon} />
      <p className={classes.name}>{name}</p>
      <pre className={classes.content}>{content}</pre>
    </div>
  )
}

export default Quote
