import React, { useEffect, useState } from "react"
import Img from "gatsby-image"

import classes from "./AboutMe.module.scss"
import Quote from "./Quote/Quote"

const AboutMe = data => {
  const [years, setYears] = useState(0)
  const duration = 3
  const endYears = data.data.lata_doswiadczenia

  useEffect(() => {
    let start = 0
    const end = endYears

    if (start === end) return

    const timer = setInterval(() => {
      start += 1
      setYears(String(start))

      if (start === end) clearInterval(timer)
    }, duration * 20)
  }, [endYears, duration])

  return (
    <div className={classes.aboutMe}>
      <section id={classes.personal}>
        <div className={classes.left_side}>
          <Img
            className={classes.image}
            fluid={data.data.zdjecie.childImageSharp.fluid}
            alt={"o mnie"}
          />
        </div>
        <div className={classes.right_side}>
          <h1 className={classes.name}>{data.data.Imie}</h1>
          <h3 className={classes.job}>{data.data.zawod}</h3>
          <pre className={classes.desc}>{data.data.opis}</pre>
          {/* <ReactMarkdown className={classes.desc}>
            {data.data.opis}
          </ReactMarkdown> */}
          <div className={classes.exp_years}>
            <p className={classes.years}>{years}</p>
            <p className={classes.label}>Lat doświadczenia</p>
          </div>
        </div>
      </section>
      <section id={classes.quotes}>
        <h2 className={classes.title}>Opinie moich klientów</h2>
        <div className={classes.items}>
          <Quote
            name={data.data.cytat1.Autor}
            content={data.data.cytat1.Tresc}
          />
          <Quote
            name={data.data.cytat2.Autor}
            content={data.data.cytat2.Tresc}
          />
          <Quote
            name={data.data.cytat3.Autor}
            content={data.data.cytat3.Tresc}
          />
        </div>
      </section>
    </div>
  )
}

export default AboutMe
